<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
        <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <UserForm></UserForm>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <!--  -->
        </div>
       </div>
            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form>
    </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import UserForm from './common/UserForm';
import { mapGetters, mapActions } from 'vuex'
import loaderMixin from '../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    UserForm,
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'user/getFormObj'
    }),
    userId() {
        return this.$route.params.userId;
    },
  },
  created() {
    this.openLoading();
    this.fetchAndSetUserById(this.userId).then((res) => this.closeLoading())
      .catch(() => {this.closeLoading(); this.$router.push('/users')});
  },
  methods: {
    ...mapActions('user', [
        'updateUserAction',
        'fetchAndSetUserById'
    ]),
    submitForm() {
      this.openLoading()
      this.updateUserAction({
        userId: this.userId,
        data: this.form
      }).then(async response => {
           if(response.status === 202) {
            this.closeLoading()
            this.$vs.notify({ 
                text: 'User updated successfully.',
                title: 'User Updated',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/users');
           }
        }).catch((e) => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
